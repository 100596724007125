import axios from 'axios';
import qs from 'qs';
import cookie from 'js-cookie';
import { getLocal } from '../utils';

export function setBaseUrl(baseUrl) {
  axios.defaults.baseURL = baseUrl;
}

export function setHeaders(headers) {
  axios.defaults.headers = headers;
}

export function get(api, params) {
  return request(api, {
    method: 'get',
    params,
  });
}

export function post(api, data, params) {
  return request(api, {
    method: 'post',
    data,
    params,
  });
}

export default function request(api, options) {
  options.headers = Object.assign({}, options.headers, {
    'lang': 'zh_CN'
  });

  const AUTH = cookie.get('authorization');
  const UA_INFO = getLocal('OC-IO') || cookie.get('OC-IO');

  if (AUTH) {
    Object.assign(options.headers, {
      'Authorization': AUTH ? `Bearer ${AUTH}` : ''
    });
  }

  if (UA_INFO) {
    Object.assign(options.headers, {
      'OC-IO': UA_INFO,
    });
  }

  if (options.method.toUpperCase() !== 'GET' && (!options.type || options.type.toUpperCase() !== 'JSON')) {
    Object.assign(options.headers, {'Content-Type': 'application/x-www-form-urlencoded'});
    options.data = qs.stringify(options.data);
  }

  return axios({
    url: api,
    ...options,
  });
}