let callbackCounter = 0;
let invokeQueue = [];
let invokeTimestamp = 0;

const INVOKE_INTERVAL = 100;
const BRIDGE_KEY = Math.round(Math.random() * 10000);
const CALLBACK_PREFIX = `jsbridge_cb_${BRIDGE_KEY}_`;

let onInvoke = null;

class JsBridge {
    constructor (protocol) {
        this.protocol = protocol.replace(/:\/\/$/, '');
    }

    onInvoke (callback) {
        onInvoke = callback;
    }

    invoke (api, params = {}) {
        let now = Date.now();
        let {protocol} = this;
        if (now - invokeTimestamp >= INVOKE_INTERVAL) {
            invokeTimestamp = now;
            parse(protocol, api, params);
            checkQueue();
        } else {
            invokeQueue.push([protocol, api, params]);
        }
        return this;
    }

    call (cb) {
        let callback = window[cb];
        let args = Array.from(arguments);
        args.shift();
        callback && callback(...args);
    }

    getUrl (api, params) {
        return toUrlScheme(this.protocol, api, params);
    }

    setProtocol (proto) {
        this.protocol = proto;
    }

    getKey() {
        return BRIDGE_KEY;
    }
}

function checkQueue () {
    setTimeout(() => {
        if (invokeQueue.length) {
            parse(...invokeQueue.shift());
            checkQueue();
        }
    }, INVOKE_INTERVAL);
}

function parse (proto, api, params) {
    let urlScheme = toUrlScheme(proto, api, params);
    exec(urlScheme);
    onInvoke && onInvoke(urlScheme);
}

function canUsePostMessage () {
    return !!(window.webkit && window.webkit.messageHandlers);
}

function exec (urlScheme) {
    if (urlScheme) {
        if (canUsePostMessage()) {
            window.webkit.messageHandlers.bridge.postMessage(urlScheme);
        } else {
            // var iframe = document.createElement('iframe');
            // var dom = document.documentElement;

            // iframe.style.cssText = 'width:1px;height:1px;display:none;';
            // iframe.src = urlScheme;
            // dom.appendChild(iframe);

            // setTimeout(() => {
            //     dom.removeChild(iframe);
            // }, 100);
            window.location.href = urlScheme;
        }
    }
}

function toUrlScheme (proto, api, params) {
    let urlScheme = '';
    let urlParams = new URLSearchParams();

    for (let key in params) {
        let value = params[key];
        // 过滤undefined
        if (typeof value !== 'undefined') {
            if (typeof value === 'function') {
                value = toCallbackId(value);
            }
            urlParams.set(key, value);
        }
    }
    let paramsStr = urlParams.toString();
    urlScheme = proto + '://' + api + (paramsStr ? `?${paramsStr}` : '');
    return urlScheme;
}

function toCallbackId (callback) {
    let funcName = CALLBACK_PREFIX + callbackCounter++;

    window[funcName] = function jsBridgeCallback () {
        if (callback(...arguments) !== true) {
            callback = null;
            window[funcName] = null;
            delete window[funcName];
        }
    };

    return funcName;
}

export default JsBridge;