import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from './context';
import JsBridge from './jsbridge';
import cookie from 'js-cookie';
import { setLocal } from './utils';

window.oneclickBridge = new JsBridge('oneclick');
const uaInfo = new URLSearchParams(window.location.search).get('u');
if (uaInfo) {
  setLocal('OC-IO', uaInfo);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider><App /></Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
