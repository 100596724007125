import './index.scss';
import React from 'react';
import closeIcon from '../../assets/close.svg';
import { ReactSVG } from 'react-svg';
import { componentWillAppendToBody } from 'react-append-to-body';

function Dialog(props) {
  return (
    <div className="widget dialog">
      <div className="body">
        {
          props.onClose ? (
            <i className="close" onClick={props.onClose}>
              {props.closeElement ? props.closeElement : <ReactSVG src={closeIcon}/>}
            </i>
          ) : null
        }
        {props.children}
      </div>
    </div>
  );
}

export default componentWillAppendToBody(Dialog);