import './index.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../context';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import checkIcon from '../../assets/check.svg';
import { ReactSVG } from 'react-svg';
import { getState, resetSubscriptionUrl } from '../../apis/apis';
import Page from '../../widgets/Page';
import { Line } from 'react-chartjs-2';

export default function User() {
  const {user, logout, isLogin} = useContext(Context);
  const subscriptionUrlRef = useRef();
  const clashUrlRef = useRef();

  const [copied, setCopied] = useState(false);
  const [copiedClash, setCopiedClash] = useState(false);
  const [stateDataList, setStateDataList] = useState(null);

  useEffect(() => {
    if (user) {
      getState().then(data => setStateDataList(data.list));
    }
  }, [user]);
  
  if (!isLogin) {
    return <Redirect to="/"/>;
  }

  if (!user) {
    return null;
  }

  return (
    <Page className="page user">
      <div className="tips">由于众所周知的原因，目前节点会受一定影响，请保持更新订阅、手动切换可用的节点。由此带来的不便，敬请谅解。</div>
      <div className="box account">
        <h1>账号</h1>
        <ul>
          <li>
            <label>Email</label>
            <span>{user.email}</span>
          </li>
          {/* <li>
            <label>ID</label>
            <span>#{user.userId}</span>
          </li> */}
        </ul>
      </div>
      <div className="box plan">
        <h1>套餐</h1>
        {
          user.plan ? (
            user.plan.status === 1 ? getPlan() : getExpired()
          ) : getEmpty()
        }
      </div>
      {
        stateDataList ? 
        <div className="box stat">
          <h1>统计</h1>
          <Line
            data={{
              labels: stateDataList.map(i => moment(i.dateTime).format('MM-DD')),
              datasets: [{
                label: `数据用量 (MB)`,
                data: stateDataList.map(i => (i.total / 1024 / 1024)),
                backgroundColor: 'rgba(245,186,69,.3)',
                borderColor: '#f5ba45',
                borderWidth: 2,
              }],
            }}
            options={{
              scales : {
                yAxes : [{
                  ticks : {
                    min : 0
                  }
                }]
              }
            }}
          />
          <p className="tips">仅显示最近7天的流量统计</p>
        </div> : null
      }
      <div className="box client">
        <h1>客户端</h1>
        <label>iOS/Android</label>
        <dl>
          <dt><a href="https://oneclick.earth" target="_blank">OneClick</a> | <a href="/help/mobileclient.html" target="_blank">帮助</a></dt>
        </dl>
        <span className="split"></span>
        <label>Windows</label>
        <dl>
          <dt><a href="https://github.com/Fndroid/clash_for_windows_pkg/releases" target="_blank">Clash for Windows</a> | <a href="/help/pcclient.html" target="_blank">帮助</a></dt>
        </dl>
        <span className="split"></span>
        <label>MacOS</label>
        <dl>
          <dt><a href="https://github.com/Fndroid/clash_for_windows_pkg/releases" target="_blank">Clash for Windows</a> | <a href="/help/pcclient.html" target="_blank">帮助</a></dt>
          <dt><a href="https://github.com/yichengchen/clashX/releases" target="_blank">ClashX</a></dt>
        </dl>
        {/* <span className="split"></span> */}
      </div>
      <div className="box service">
        <h1>服务</h1>
        <label>官方电报</label>
        <dl>
          <dt><a href="https://t.me/fanwallbot" target="_blank">FanWall机器人</a></dt>
          <dd>获取帮助、反馈问题。绑定账号后即可及时收到账户通知推送。</dd>
          <dt><a href="https://t.me/fanwallnews" target="_blank">FanWall通知</a></dt>
          <dd>获取FanWall限时优惠和其他通知信息。</dd>
        </dl>
        <span className="split"></span>
        <label>客服邮箱</label>
        <dl>
          <dt><a href="mailto:customer@fw321.xyz">customer@fw321.xyz</a></dt>
        </dl>
        <span className="split"></span>
        <label>帮助</label>
        <dl>
          <dt><a href="/help" target="_blank">使用帮助</a></dt>
        </dl>
      </div>
      <div className="box logout">
        <button onClick={handleLogout}>退出</button>
      </div>
    </Page>
  );

  function handleLogout() {
    window.gtag('event', 'logout');
    logout();
  }

  async function reset() {
    if(window.confirm('确定要重置？')) {
      window.gtag('event', 'reset_sub_url');
      await resetSubscriptionUrl();
      // window.location.reload();
    }
  }

  function importSub() {
    // const input = subscriptionUrlRef.current;
    window.gtag('event', 'import_sub');

    const scheme = window.oneclickBridge.getUrl('importViaSub', {
      name: `FanWall`,
      url: user.plan.subscriptionUrl,
      webSite: `https://fw321.xyz/#/user`,
      icon: `${window.location.protocol}//${window.location.host}/logo@2x.png`,
    });
    // input.value = scheme;
    // copy(input);
    window.location.href = scheme;
  }

  function importSubClash() {
    window.gtag('event', 'import_sub', {
      event_label: 'clash'
    });

    window.location.href = `clash://install-config?url=${encodeURIComponent(user.plan.clashUrl)}&name=FanWall`;
  }

  function copy(target) {
    window.gtag('event', 'copy_sub_url', {
      event_label: /clash/i.test(target) ? 'clash' : ''
    });

    target.select();
    document.execCommand('copy');
  }

  function handleRenew(isExpired) {
    window.gtag('event', 'click_renew', {
      'event_label': isExpired
    })
  }

  function getExpired() {
    return (
      <div className="planCard">
        <div className="content">
          <div className="summary">
            <div className="item date">
              <label>有效期至</label>
              <span>{moment(user.plan.expireTime).format('yyyy-MM-DD').toString()} 已过期</span>
              <Link className="btn renew" to="/" onClick={() => handleRenew(true)}>续费</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getPlan() {
    const usedKB = user.plan.usage / 1024;
    const usedMB = usedKB/ 1024;
    const usedGB = usedMB / 1024;
    const quotaMB = user.plan.quota;
    const quotaGB = user.plan.quota / 1024;
    const dataUsage = Math.round(100 * (usedMB / quotaMB));

    function getUsed() {
      let used;
      if (usedGB >= 1) {
        used = [usedGB, 'GB'];
      } else if (usedMB >= 1) {
        used = [usedMB, 'MB'];
      } else {
        used = [usedKB, 'KB'];
      }
      return used[0].toFixed(2) + used[1];
    }

    const isWarning = moment(user.plan.expireTime).diff(moment(), 'days') <= 7;

    return (
      <div className="planCard">
        <div className="content">
          <div className="summary">
            <div className="item date">
              {
                isWarning ? (
                  <label className="warning">即将到期</label>
                ) : (
                  <label>有效期至</label>
                )
              }
              <span>{moment(user.plan.expireTime).format('yyyy-MM-DD').toString()}</span>
              <Link className="btn renew" to="/" onClick={handleRenew}>续费</Link>
            </div>
            <div className="item usage">
              <label>数据用量</label>
              <div className="progress">
                <div className="bar">
                  <var style={{width: `${dataUsage}%`}}/>
                </div>
                <div className="value"></div>
              </div>
              <div className="reset">已用{getUsed()} / {quotaGB}GB，将于{moment(user.plan.resetTime).format('yyyy-MM-DD').toString()}重置</div>
            </div>
          </div>
          <span className="split"></span>
          <div className="subcription">
            <label>订阅地址（适用于OneClick等客户端）</label>
            <div className="input">
              <input ref={subscriptionUrlRef} onBlur={() => setCopied(false)} readOnly value={user.plan.subscriptionUrl}/>
              <span className="copy">
                {
                  copied ?
                  <span className="success">已复制 <ReactSVG className="icon" src={checkIcon}/></span>
                  : 
                  <>
                    <span onClick={() => importSub()}>导入</span>
                    <span onClick={() => {copy(subscriptionUrlRef.current);setCopied(true)}}>复制</span>
                  </>
                }
              </span>
            </div>
          </div>
          <div className="subcription">
            <label>托管配置（适用于Clash客户端）</label>
            <div className="input">
              <input ref={clashUrlRef} onBlur={() => setCopiedClash(false)} readOnly value={user.plan.clashUrl}/>
              <span className="copy">
                {
                  copiedClash ?
                  <span className="success">已复制 <ReactSVG className="icon" src={checkIcon}/></span>
                  : 
                  <>
                    <span onClick={() => importSubClash()}>导入</span>
                    <span onClick={() => {copy(clashUrlRef.current);setCopiedClash(true)}}>复制</span>
                  </>
                }
              </span>
            </div>
            <p className="tips">订阅地址及托管配置和密码一样重要，请勿分享给他人</p>
            <p className="reset"></p>
          </div>
        </div>
      </div>
    );
  }

  function getEmpty() {
    return (
      <div className="empty">
        <p>暂无套餐</p>
        <p><Link className="btn" to="/">立即购买</Link></p>
      </div>
    );
  }
}