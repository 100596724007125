import './index.scss';
import Header from '../../widgets/Header';

export default function Page({
  className,
  children,
}) {
  return <div className={className}>
    <Header/>
    {children}
  </div>
}