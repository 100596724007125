import { get, post, setBaseUrl, setHeaders } from "./request";
import { createHashHistory } from 'history';
import cookie from 'js-cookie';
import moment from 'moment';

export const CODE_SUCCESS = 0;
export const CODE_NOT_LOGIN = -1;

setBaseUrl('/api');

export async function register(username, password) {
  const data = check(await post('/login/email/register', {email: username, pwd: password}));
  cookie.set('authorization', data.token);
  return data;
}

export async function login(username, password) {
  const data = check(await post('/login/email', {email: username, pwd: password}));
  cookie.set('authorization', data.token);
  return data;
}

export async function getUserInfo() {
  return check(await get('/user/info'));
}

export async function resetSubscriptionUrl() {
  return check(await post('/user/plan/reset'));
}

export async function getState() {
  return check(await get('/user/plan/state', {
    startTime: moment().subtract(7, 'days').toDate().getTime(),
    endTime: Date.now(),
  }));
}

export async function getAllPlans(coupon) {
  return check(await get('/plan/list', {coupon}));
}

export async function prepay(payType, planId, coupon) {
  return check(await post('/order/prepay', {
    payType,
    planId,
    coupon
  }));
}

export async function freeTrial(planId) {
  return check(await post('/order/free', {
    planId
  }));
}

export async function checkOrder(orderId) {
  return check(await post('/order/check', {
    orderId
  }));
}

export async function resetDevice(email) {
  return await get('/api/device/reset', {
    email
  });
}

function check(response) {
  const {code, data, msg} = response.data;
  if (code !== CODE_SUCCESS) {
    if (code === CODE_NOT_LOGIN) {
      createHashHistory().replace('/?login=true');
    } else {
      const err = new Error(msg || code);
      err.data = data;
      err.code = code;
      throw err;
    }
  }
  return data;
}