import React, { useEffect, useState } from 'react';
import { getAllPlans, getUserInfo, login, register } from './apis/apis';
import cookie from 'js-cookie';
import { createHashHistory } from 'history';
import { getLocal, setLocal } from './utils';

const Context = React.createContext();
const emptyFunction = () => {};

export default Context;

export function Provider(props) {
  const [isLogin, setIsLogin] = useState(!!cookie.get('authorization'));
  const [user, setUser] = useState(null);
  const [allPlans, setAllPlans] = useState([]);
  const [chosenPlan, setChosenPlan] = useState({});
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [isShowReg, setIsShowReg] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isShowCoupon, setIsShowCoupon] = useState(false);

  const [loginHandler, setLoginHandler] = useState({
    onSuccess: emptyFunction,
    onFail: emptyFunction,
    onCancel: emptyFunction,
  });
  const [couponHandler, setCouponHandler] = useState(emptyFunction);
  

  const state = {
    isLogin,
    user,
    isShowLogin,
    isShowReg,
    isContinue,
    allPlans,
    chosenPlan,
    loginHandler,
    isOldUser: getLocal('isOldUser'),
    isShowCoupon,

    // actions
    choosePlan: setChosenPlan,
    showLogin: (isContinue, handler) => {
      handler !== undefined && setLoginHandler(handler);
      isContinue !== undefined && setIsContinue(isContinue);
      setIsShowReg(false);
      setIsShowLogin(true);
    },
    hideLogin: () => {
      setLoginHandler({});
      setIsContinue(false);
      setIsShowLogin(false);
      setIsShowReg(false);
    },
    showReg: (isContinue, handler) => {
      handler !== undefined && setLoginHandler(handler);
      isContinue !== undefined && setIsContinue(isContinue);
      setIsShowReg(true);
      setIsShowLogin(true);
    },
    hideReg: () => {
      setLoginHandler({});
      setIsContinue(false);
      setIsShowLogin(false);
      setIsShowReg(false);
    },
    register: async (username, password) => {
      const data = await register(username, password);
      setUser(data);
      setIsLogin(true);
      setLocal('isOldUser', true);
    },
    login: async (username, password) => {
      await login(username, password);
      await state.getUser();
      setIsLogin(true);
      setLocal('isOldUser', true);
    },
    logout: () => {
      cookie.remove('authorization');
      setIsLogin(false);
      setUser(null);
    },
    getUser: async () => {
      const data = await getUserInfo();
      setUser(data);
      return data;
    },
    getAllPlans: async (coupon) => {
      const {list} = await getAllPlans(coupon);
      setAllPlans(list);
      return list;
    },
    redirect: path => {
      createHashHistory().push(path);
    },
    showCoupon: (couponHandler) => {
      setCouponHandler(couponHandler);
      setIsShowCoupon(true);
    },
    hideCoupon: () => {
      setCouponHandler(emptyFunction);
      setIsShowCoupon(false);
    },
  };

  useEffect(() => {
    const auth = cookie.get('authorization');
    setIsLogin(!!auth);
    if (auth) {
      state.getUser();
    }
  }, []);

  return (
    <Context.Provider value={state}>{props.children}</Context.Provider>
  )
}