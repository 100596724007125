import './index.scss';
import React from 'react';
import Dialog from '../Dialog';

function LandingDialog(props) {
  return (
    <Dialog>
      <div class="landing">
        <h2>您已获得限时6折优惠券</h2>
        <h1>fw2021</h1>
        <ul class="tips">
          <li>Trojan协议，安全稳定</li>
          <li>新加坡、日本、美国高速节点</li>
          <li>全节点解锁NetFlix等流媒体</li>
          <li>每月50GB流量，不限终端，不限速度</li>
          <li>通用订阅接口，适配大多数科学上网客户端</li>
        </ul>
        <button onClick={props.onConfirm} class="confirm">立即使用，仅需 ¥12.00</button>
        <a onClick={props.onLater} class="later" href="javascript:void(0);">稍后使用</a>
      </div>
    </Dialog>
  );
}

export default LandingDialog;