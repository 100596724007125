import './index.scss';
import Page from '../../widgets/Page';
import Dialog from '../../widgets/Dialog';
import LandingDialog from '../../widgets/LandingDialog';
import { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../context';
import { prepay, freeTrial, resetDevice } from '../../apis/apis';
import { loadStripe } from '@stripe/stripe-js';
import checkboxImg from '../../assets/checkbox.svg';
import checkboxCheckedImg from '../../assets/checkbox_checked.svg';
import loadingImg from '../../assets/loading.svg';
import slide1Img from '../../assets/apps@2x.png';
import slide2Img from '../../assets/world.svg';
import slide3Img from '../../assets/relax.svg';
import slide4Img from '../../assets/faq.svg';
import cookie from 'js-cookie';
import { getLocal, setLocal } from '../../utils';

import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

export default function Home(props) {
  const {chosenPlan, choosePlan, allPlans, getAllPlans, isLogin, showReg, showLogin, redirect} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [isShowCoupon, setIsShowCoupon] = useState(false);
  const [lowestPlan, setLowestPlan] = useState({});
  const [coupon, setCoupon] = useState('');
  const [isAppCoupon, setIsAppCoupon] = useState(false);
  const couponRef = useRef();
  const isAppUser = !!(getLocal('OC-IO') || cookie.get('OC-IO'));
  const isOldUser = !!getLocal('isOldUser');
  const [isSlow, setIsSlow] = useState(false);
  const couponFromUrl = new URLSearchParams(window.location.search).get('c');
  const [isShowLanding, setIsShowLanding] = useState(couponFromUrl === 'fw2021');

  useEffect(() => {
    getAllPlans(couponFromUrl)
      .then(plans => {
        // const plan = plans.filter(i => i.originalPrice !== 0).sort((a, b) => a.priceUnit - b.priceUnit)[0];
        choosePlan(plans[0]);
        setLowestPlan(plans[0]);
        if (couponFromUrl) {
          setCoupon(couponFromUrl);
        }
      })
      .catch(e => {
        if (e.code === -568) {
          getAllPlans()
            .then(plans => {
              choosePlan(plans[0]);
              setLowestPlan(plans[0]);
            });
        }
      });

    const params = new URLSearchParams(props.location.search);
    if (params.get('login') === 'true') {
      showLogin();
    }

  }, []);

  useEffect(() => {
    if (!coupon && isAppUser && chosenPlan.salePrice !== chosenPlan.originalPrice) {
      setIsAppCoupon(true);
    }
  }, [chosenPlan]);

  return <Page className="page home">
    <div className="swiper">
      <Swiper
        pagination
        loop
        autoplay={{
          delay: 5000
        }}
      >
        <SwiperSlide className="slide">
          <h1>欢迎使用FanWall</h1>
          <p>突破互联网封锁，尽情使用世界各地的App和服务</p>
          <p>海外专业公司运营，服务安全有保障</p>
          <p className="pic"><img className="smaller" src={slide1Img}/></p>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <h1>安全、稳定、高速</h1>
          <p>主营新加坡、日本、美国等优质线路，持续布局全球主要地区</p>
          <p>节点采用迄今最稳的Trojan协议，特殊时期依然稳定</p>
          <p className="pic"><img className="smaller" src={slide2Img}/></p>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <h1>多终端、跨平台</h1>
          <p>支持 iOS/Android/Windows/MacOS 等客户端订阅</p>
          <p>多终端共享每月多达{lowestPlan.quota / 1024}GB高速流量</p>
          <p className="pic"><img className="smaller" src={slide3Img}/></p>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <h1>获取优惠和帮助信息</h1>
          <p>查看<a href="/help" target="_blank">帮助文档</a>，关注我们的<a href="https://t.me/fanwallbot" target="_blank">电报机器人</a>和<a href="https://t.me/fanwallnews" target="_blank">电报通知频道</a></p>
          <p className="pic"><img className="smaller" src={slide4Img}/></p>
        </SwiperSlide>
      </Swiper>
    </div>
    <div className="order">
      <ul className={`plans ${loading ? 'disabled' : ''}`}>
        {allPlans.map(i => (
          <li key={i.id} onClick={() => loading ? null : choosePlan(i)}>
            <img src={chosenPlan.id === i.id ? checkboxCheckedImg : checkboxImg} className="checkbox"/>
            <span className="title">{i.title}</span>
            <span className="summary">
              {i.priceUnit === 0 ? 
                `${i.quota/1024}GB`
                : (
                  i.salePriceUnit === i.priceUnit ?
                    (
                      <span>{handlePrice(i.priceUnit)}/{i.quota/1024}GB {i.priceUnit !== i.originalPrice ? '每月' : ''}</span>
                    )
                    : (
                      <span><del>{handlePrice(i.priceUnit)}</del>{handlePrice(i.salePriceUnit)}/{i.quota/1024}GB {i.priceUnit !== i.originalPrice ? '每月' : ''}</span>
                    )
                )
              }
            </span>
            {i.promotion ? <span className="desc promotion">单价最低</span> : (
              i.description ? <span className="desc">{i.description}</span> : null
            )}
          </li>
        ))}
      </ul>
      {
        loading ? (
          <button disabled className="button pay">
            <img src={loadingImg} className="loading" /> {isSlow ? '海外支付响应较慢，请耐心等待…' : '请稍候…'}
          </button>
        ) : (
          chosenPlan.originalPrice === 0 ? (
            <button className="button pay" onClick={() => handleFreeTrial()}>免费试用</button>
          ) : (
            allPlans && allPlans.length ? (
              <button className="button pay" onClick={() => handlePay()}>{
                chosenPlan.salePrice === chosenPlan.originalPrice ? (
                  <span>支付 {handlePrice(chosenPlan.salePrice)}</span>
                ) : (
                  <span><del>原价 {handlePrice(chosenPlan.originalPrice)}</del>优惠价 {handlePrice(chosenPlan.salePrice)}</span>
                )
              }</button>
            ) : null
          )
        )
      }
      <div className="useCoupon">
        {
          isAppCoupon ? (
            <p className="app">已享用App专属优惠</p>
          ) : null
        }
        {
          coupon ? (
            <p>已使用优惠券: {coupon} <a onClick={() => cancelCoupon()}>取消</a></p>
          ) : (
            <a onClick={() => setIsShowCoupon(true)}>使用优惠券</a>
          )
        }
      </div>
    </div>
    {
      loading ? (
        <div className="cover"></div>
      ) : null
    }

    {
      isShowCoupon ? (
        <Dialog onClose={() => setIsShowCoupon(false)}>
          <div className="coupon">
            <h1>使用优惠券</h1>
            <div className="input">
              {/* <label>优惠券码</label> */}
              <input placeholder="请输入优惠码" ref={couponRef} type="text"/>
              <button onClick={() => inputCoupon(couponRef.current.value)}>使用</button>
            </div>
            <div className="how">
              <p><strong>如何获得优惠券？</strong></p>
              <p>请关注我们的<a href="https://t.me/fanwallbot" target="_blank">电报机器人</a>和<a href="https://t.me/fanwallnews" target="_blank">电报通知频道</a>，不定期发放优惠券。</p>
              <div className="split"></div>
              <p>如果与其他优惠同时使用，优惠后的价格以界面显示的优惠价为准。</p>
            </div>
          </div>
        </Dialog>
      ) : null
    }

    {
      isShowLanding ? (
        <LandingDialog onConfirm={() => handlePay() && setIsShowLanding(false)} onLater={() => setIsShowLanding(false)} />
      ) : null
    }
  </Page>

  function handlePrice(num) {
    return `¥${(num / 100).toFixed(2)}`;
  }

  async function inputCoupon(coupon) {
    try {
      const plans = await getAllPlans(coupon);
      choosePlan(plans.find(i => i.id === chosenPlan.id));
      setCoupon(coupon);
      setIsShowCoupon(false);
    } catch(e) {
      alert(e.message);
    }
  }

  async function cancelCoupon() {
    const plans = await getAllPlans();
    choosePlan(plans.find(i => i.id === chosenPlan.id));
    setCoupon('');
  }

  async function handleFreeTrial() {
    setLoading(true);

    window.gtag('event', 'click_free_trial');

    try {
      await handleLogin();
      await freeTrial(chosenPlan.id);
    } catch(e) {
      if (e) {
        if (e.code === -59) {
          redirect('/pay/success?type=free');
        } else if (e.message) {
          alert(e.message);
        }
      }
      setLoading(false);
    }
  }

  async function handlePay() {
    setLoading(true);
    setIsSlow(false);

    window.gtag('event', 'click_pay', {
      'event_label': chosenPlan.id
    });

    try {
      await handleLogin();
      setTimeout(() => setIsSlow(true), 5000);

      const {result: {publicKey, sessionId}} = await prepay(3, chosenPlan.id, coupon);
      const stripe = await loadStripe(publicKey);
      stripe.redirectToCheckout({
        sessionId,
      });
    } catch(e) {
      if (e && e.message) {
        alert(e.message);
      }
      setLoading(false);
    }
  }

  function handleLogin() {
    return new Promise((resolve, reject) => {
      if (isLogin) {
        resolve();
      } else {
        isOldUser ? 
          showLogin(true, {
            onSuccess: resolve,
            onCancel: reject,
            // onFail: reject,
          }) :
          showReg(true, {
            onSuccess: resolve,
            onCancel: reject,
            // onFail: reject,
          });
      }
    });
  }
}