import './index.scss';
import React, { useContext, useRef, useState } from 'react';
import Context from '../../context';
import showPasswordImg from '../../assets/show.svg';
import hidePasswordImg from '../../assets/hide.svg';

export default function Login (props) {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const {showLogin, hideLogin, showReg, hideReg, isContinue, login, register} = useContext(Context);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="widget login">
      {props.isReg ? getReg() : getLogin()}
    </div>
  );

  function getForm() {
    return (
      <ul>
        <li>
          <label>邮箱</label>
          <input ref={usernameRef} type="email" />
        </li>
        <li>
          <label>密码</label>
          <input autoCapitalize="off" ref={passwordRef} type={isShowPassword ? 'text' : 'password'} />
          <span className="togglePassword" onClick={() => {setIsShowPassword(!isShowPassword)}}>
            {
              isShowPassword ? (
                <img src={showPasswordImg}/>
              ) : (
                <img src={hidePasswordImg}/>
              )
            }
          </span>
        </li>
      </ul>
    );
  }

  function getLogin() {
    return (
      <div className="login">
        <h1><span>登录</span></h1>
        <form>
          {getForm()}
          <button type="button" onClick={handleLogin}>{isContinue ? '继续' : '登录'}</button>
        </form>
        <p className="change">还没有账号？<a onClick={() => showReg()}>注册</a></p>
      </div>
    );
  }

  function getReg() {
    return (
      <div className="reg">
        <h1>
          {
            isContinue ? (
              <>
                <span>注册</span>
                <span className="tips">你需要注册一个账号来查看和管理购买的套餐</span>
              </>
            ) : (
              <span>注册</span>
            )
          }
        </h1>
        <form>
          {getForm()}
          <div className="rule">
            <strong>请注意</strong>
            <p>1、FanWall十分注重你的隐私安全，你的邮箱会被加密妥善处理，仅用于登录验证及服务通知；</p>
            <p>2、FanWall提供的服务仅用于学习、外贸、社交、视频等日常用途，严禁利用本服务发布涉及政治、盗版等非法内容。FanWall有权封禁违反上述规则的用户。</p>
          </div>
          <button type="button" onClick={handleReg}>{isContinue ? '继续' : '注册'}</button>
        </form>
        <p className="change">已有账号？<a onClick={() => showLogin(isContinue)}>登录</a></p>
      </div>
    );
  }

  async function handleReg() {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (username && password) {
      window.gtag('event', 'reg');
      try {
        await register(username, password);
        props.onSuccess();
      } catch(e) {
        alert(e.message);
        // props.onFail(e);
      }
    }
  }

  async function handleLogin() {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (username && password && !loading) {
      window.gtag('event', 'login');
      setLoading(true);
      try {
        await login(username, password);
        props.onSuccess();
      } catch(e) {
        alert(e.message);
        // props.onFail(e);
      }
      setLoading(false);
    }
  }
}