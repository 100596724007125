import './index.scss';

import logoImg from '../../assets/logo.svg';
import accountImg from '../../assets/account.svg';
import helpImg from '../../assets/help.svg';
import Context from '../../context';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getLocal } from '../../utils';

export default function Header() {
  const { showReg, showLogin, user, redirect } = useContext(Context);
  const isOldUser = !!getLocal('isOldUser');

  return <div className="widget header">
    <div className="fixed">
      <h1 className="logo"><Link to="/"><img src={logoImg}/></Link></h1>

      <div className="ext">
        {
          user ? (
            <Link className="item" to="/user">
              {/* <span>账户</span> */}
              <span>{user.email.split('@')[0]}</span>
              <img className="icon" src={accountImg}/>
            </Link>
          ) : (
            <a className="item" onClick={handleClickLogin}>
              {/* <span><span onClick={handleClickReg}>注册</span> | <span onClick={handleClickLogin}>登录</span></span> */}
              <img className="icon" src={accountImg}/>
            </a>
          )
        }
        <a href="/help" target="_blank" className="item">
          {/* <span>帮助</span> */}
          <img className="icon" src={helpImg}/>
        </a>
      </div>
    </div>
  </div>

  // function handleClickReg() {
  //   showReg(false, {onSuccess: () => redirect('/user')});
  // }

  // function handleClickLogin() {
  //   showLogin(false, {onSuccess: () => redirect('/user')});
  // }

  function handleClickLogin() {
    if (isOldUser) {
      showLogin(false, {onSuccess: () => redirect('/user')});
    } else {
      showReg(false, {onSuccess: () => redirect('/user')});
    }
  }
}