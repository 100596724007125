import './index.scss';
import Page from '../../widgets/Page';
import { useContext, useEffect, useRef, useState } from 'react';
import { checkOrder, CODE_NOT_LOGIN } from '../../apis/apis';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import cookie from 'js-cookie';
import Context from '../../context';
import Dialog from '../../widgets/Dialog';
import successImg from '../../assets/success@2x.png';
import pendingImg from '../../assets/pending@2x.png';
import telegramImg from '../../assets/telegram.svg';
import { getLocal } from '../../utils';

export default function Result(props) {
  const {user, isLogin, getUser, redirect} = useContext(Context);
  const params = new URLSearchParams(props.location.search);
  const isFree = params.get('type') === 'free';
  const orderId = params.get('id');
  const [status, setStatus] = useState(-1);
  const [isShowHelp, setIsShowHelp] = useState(false);
  // const subscriptionUrlRef = useRef();
  const isAppUser = !!(getLocal('OC-IO') || cookie.get('OC-IO'));
  const SHOW_DIALOG_KEY = 'showDialog';
  const needShowDialog = !cookie.get(SHOW_DIALOG_KEY);

  const isDesktop = window.screen.width > 1024;

  const OrderStatus = {
    payPending: 1,
    paid: 2,
    cancel: 3,
    timeout: 4,
    refundPending: 5,
    refunded: 6,
  };
  let checkTimeoutId = 0;

  useEffect(() => {
    if (orderId) {
      autoCheckOrder();
    } else if (isFree) {
      autoCheckVerify();
    }
    return () => clearTimeout(checkTimeoutId);
  }, []);

  if (!isLogin) {
    return <Redirect to="/"/>
  } else if (!orderId && !isFree) {
    return <Redirect to="/user"/>
  } else if (!user) {
    return null;
  }

  return (
    <Page className="page result">
      {isFree ? getFree() : getOrder()}
    </Page>
  )

  // function copy(target) {
  //   target.select();
  //   document.execCommand('copy');
  // }

  function importSub() {
    window.gtag('event', 'import_sub');

    cookie.set(SHOW_DIALOG_KEY, 1);
    // const input = subscriptionUrlRef.current;
    const {oneclickBridge, location: {protocol, host}} = window;
    const scheme = oneclickBridge.getUrl('importViaSub', {
      name: `FanWall`,
      url: user.plan.subscriptionUrl,
      webSite: `https://fw321.xyz/#/user`,
      icon: `${protocol}//${host}/logo@2x.png`,
    });
    // input.value = scheme;
    // copy(input);
    window.location.href = scheme;
  }

  function getSuccess(text = '支付') {
    return (
      <div className="paid">
        <div className="summary">
          <img src={successImg}/> <h1>{text}成功</h1>
        </div>
        <p className="tips">套餐已生效{user.plan ? <>，有效期至{moment(user.plan.expireTime).format('yyyy-MM-DD').toString()}</> : null}</p>
        {
          isDesktop ? (
            <p></p>
          ) : (
            <p className="btn">
              {/* <input ref={subscriptionUrlRef} className="hidden" /> */}
              <button onClick={importSub}>一键导入OneClick</button>
            </p>
          )
        }
        <p className="gotoUser">
          <Link to="/user">查看你的账户</Link>
        </p>

        <div className="notice">
          <p><strong>请注意</strong></p>
          <p>1、首次使用，需要将套餐的订阅地址添加或导入到客户端，点击导入按钮或在<Link to="/user">你的账户</Link>查看订阅地址；</p>
          <p>2、套餐续费，有效期将自动延长，已过期的用户续费后在客户端更新订阅即可恢复使用；</p>
          <p>3、手机客户端推荐使用<a href="https://oneclick.earth">OneClick</a>，一键导入订阅。</p>
          <div className="split"></div>
          <p>如有疑问，请查看<a href="/help" target="_blank">帮助文档</a>，或通过<a href="https://t.me/fanwallbot" target="_blank">电报</a>、<a href="mailto:customer@fw321.xyz">邮件</a>联系我们。</p>
        </div>

        {/* <div className="addTelegram">
          <p><img className="icon" src={telegramImg}/> Telegram(电报)</p>
          <p><a href="https://t.me/fanwallbot" target="_blank">FanWall机器人</a></p>
          <p><a href="https://t.me/fanwallnews" target="_blank">FanWall通知</a></p>
        </div> */}

        {
          needShowDialog && isAppUser && isShowHelp && !isDesktop ? (
            <Dialog onClose={() => setIsShowHelp(false)}>
              <div className="help">
                <h1>还差一步</h1>
                <p>你的套餐已经生效，只需将订阅地址添加到OneClick即可开始使用！</p>
                <p className="import">
                  <button onClick={importSub}>一键导入OneClick</button>
                  {/* 或在 <Link to="/user">你的账号</Link> 复制链接手动添加 */}
                </p>
                {/* <p className="telegram">如果你使用<a href="https://telegram.org/" target="_blank"><img className="icon" src={telegramImg}/>Telegram(电报)</a>，请添加<a href="https://t.me/fanwallbot" target="_blank">FanWall机器人</a>和<a href="https://t.me/fanwallnews" target="_blank">FanWall通知</a>，以获得更便捷的服务。</p> */}
              </div>
            </Dialog>
          ) : null
        }
      </div>
    )
  }

  function getFree() {
    return <>
      {status === OrderStatus.paid ? (
        getSuccess('验证')
      ) : (
        <div className="pending">
          <div className="summary">
            <img src={pendingImg}/> <h1>等待验证</h1>
          </div>
          <p className="tips">一封验证邮件已发送到 <strong>{user.email}</strong>，<br/>请登录邮箱，按邮件提示进行操作。</p>
          <p className="tips">如未收到邮件，请检查“垃圾邮件”，或 <Link to="/">重试</Link>，<br/><Link to="/">购买套餐</Link> 无需验证邮箱。</p>

          <div className="contact">
            <p className="tips">客服邮箱：<a href="mailto:customer@fw321.xyz">customer@fw321.xyz</a></p>
            <p className="tips">客服电报：<a href="https://t.me/fanwallbot" target="_blank">FanWall机器人</a></p>
          </div>
        </div>
      )}
    </>
  }

  function getOrder() {
    return (
      <>
        {status === OrderStatus.payPending ? (
          <div className="pending">
            <div className="summary">
              <img src={pendingImg}/> <h1>请稍后</h1>
            </div>
            <p className="tips">正在确认支付结果</p>
          </div>
        ) : null}
        {status === OrderStatus.paid ? (
          getSuccess()
        ) : null}
      </>
    )
  }

  async function autoCheckVerify() {
    try {
      const {plan} = await getUser();
      if (!plan) {
        checkTimeoutId = setTimeout(() => autoCheckVerify(), 10000);
      } else {
        setStatus(OrderStatus.paid);
        setTimeout(() => setIsShowHelp(true), 1000);
      }
    } catch {}
  }

  async function autoCheckOrder() {
    try {
      const {status} = await checkOrder(orderId);
      setStatus(status);
      if (status === 1) {
        checkTimeoutId = setTimeout(() => autoCheckOrder(), 3000);
      } else if(status === 2) {
        await getUser();
        setTimeout(() => setIsShowHelp(true), 1000);
      }
    } catch {}
  }
}