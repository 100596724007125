import './App.scss';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import Home from './pages/Home';
import User from './pages/User';
import Result from './pages/Result';
import Login from './widgets/Login';
import Dialog from './widgets/Dialog';
import Context from './context';
import events, { Events } from './events';

function App() {
  const { isShowLogin, isShowReg, hideLogin, loginHandler } = useContext(Context);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/user" component={User}/>
        <Route exact path="/pay/success" component={Result}/>
        <Route exact path="/pay/cancel" component={() => <Redirect to="/"/>}/>
        <Route exact path="/pay/fail" component={() => <Redirect to="/"/>}/>
      </Switch>
      {
        isShowLogin ? (
          <Dialog
            onClose={() => {
              hideLogin();
              loginHandler.onCancel && loginHandler.onCancel();
              events.emit(Events.loginCancel);
            }}>
              <Login
                onSuccess={() => {
                  hideLogin();
                  loginHandler.onSuccess && loginHandler.onSuccess();
                  events.emit(Events.login);
                }}
                onFail={loginHandler.onFail}
                isReg={isShowReg}
              />
          </Dialog>
        ) : (
          null
        )
      }
    </Router>
  );
}

export default App;
